import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC, MouseEvent } from 'react';

export type LinkProps = {
  href?: string;
} & Omit<ChakraLinkProps, 'href'>;

export const Link: FC<LinkProps> = ({
  href,
  color = 'secondary',
  onClick,
  children,
  ...props
}) => {
  return (
    <ChakraLink
      as={NextLink}
      href={href || '#'}
      passHref
      color={color}
      onClick={
        onClick
          ? (e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              onClick(e);
            }
          : undefined
      }
      {...props}
    >
      {children}
    </ChakraLink>
  );
};

Link.displayName = 'Link';
