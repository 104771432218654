import NextLink from 'next/link';
import { FC } from 'react';
import { Button, ButtonProps } from '../Button/Button';

export type ButtonLinkProps = {
  href: string;
  isExternal?: boolean;
} & Omit<ButtonProps, 'href' | 'isExternal'>;

export const ButtonLink: FC<ButtonLinkProps> = ({
  href,
  isExternal,
  children,
  ...props
}) => {
  return (
    <Button
      as={NextLink}
      href={href}
      passHref
      target={isExternal ? '_blank' : undefined}
      {...props}
    >
      {children}
    </Button>
  );
};
ButtonLink.displayName = 'ButtonLink';
