import { Box } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

export type WrapperProps = {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
};

export const Wrapper: FC<WrapperProps> = ({ header, footer, children }) => {
  return (
    <Box>
      {header}
      <Box pb={footer ? 20 : 4}>{children}</Box>
      {footer}
    </Box>
  );
};

Wrapper.displayName = 'Wrapper';
