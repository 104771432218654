import type { NextPage } from 'next';
import IndexPage from '@/components/pages/Index';
import { withSessionSsr } from '../server/middleware/withSession';

export const getServerSideProps = withSessionSsr(
  async (context) => {
    return {
      props: {},
    };
  },
  {
    notAuthRequired: true,
  }
);

const Index: NextPage = () => {
  return <IndexPage />;
};

export default Index;
