import { Box, BoxProps } from '@chakra-ui/react';
import { ImageProps } from 'next/image';
import { FC } from 'react';
import { CoverImage } from '../CoverImage/CoverImage';

export type BackgroundImageProps = {} & ImageProps & Pick<BoxProps, 'bg'>;

export const BackgroundImage: FC<BackgroundImageProps> = ({
  alt,
  src,
  bg,
  ...props
}) => {
  return (
    <>
      <CoverImage style={{ zIndex: -1 }} alt={alt} src={src} {...props} />
      {bg && (
        <Box
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={bg}
          zIndex={-1}
        />
      )}
    </>
  );
};

BackgroundImage.displayName = 'BackgroundImage';
