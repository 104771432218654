import {
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type ContainerProps = ChakraContainerProps;

export const Container: FC<ContainerProps> = ({ children, ...props }) => {
  return <ChakraContainer {...props}>{children}</ChakraContainer>;
};

Container.displayName = 'Container';
