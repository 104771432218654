import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react';

const colors = {
  default: {
    bg: 'base',
    color: 'textLight',
    _hover: {
      boxShadow:
        '0px 6px 12px rgba(44, 39, 56, 0.06), 0px 12px 24px rgba(20, 20, 20, 0.1)',
    },
    _active: {
      bg: 'base',
      opacity: 0.8,
    },
  },
  accent: {
    bg: 'secondary',
    color: 'textLight',
    _hover: {
      boxShadow:
        '0px 6px 12px rgba(44, 39, 56, 0.06), 0px 12px 24px rgba(44, 39, 56, 0.1)',
    },
    _active: {
      bg: 'secondary',
      opacity: 0.8,
    },
  },
  danger: {
    bg: 'error',
    color: 'textLight',
    _hover: {
      boxShadow:
        '0px 6px 12px rgba(44, 39, 56, 0.06), 0px 12px 24px rgba(44, 39, 56, 0.1)',
    },
    _active: {
      bg: 'error',
      opacity: 0.8,
    },
  },
  outline: {
    bg: 'white',
    color: 'textDark',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'placeholder',
    _hover: {
      boxShadow:
        '0px 6px 12px rgba(44, 39, 56, 0.06), 0px 12px 24px rgba(20, 20, 20, 0.1)',
    },
    _active: {
      bg: 'white',
      opacity: 0.8,
    },
  },
};

const disabledColor = {
  bg: 'base',
  color: 'textLight',
  _hover: {
    bg: 'base',
  },
  _active: {
    bg: 'base',
  },
};

const sizes: Record<string, ChakraButtonProps> = {
  default: {
    w: 'full',
    maxW: '400px',
    h: '48px',
  },
  sm: {
    h: 8,
    px: 4,
    fontSize: 'sm',
  },
};

export type ButtonProps = {
  color?: keyof typeof colors;
  size?: keyof typeof sizes;
} & Omit<ChakraButtonProps, 'color'>;

export const Button = forwardRef<ButtonProps, 'button'>(
  (
    {
      color = 'default',
      size = 'default',
      disabled: defaultDisabled,
      isLoading,
      ...props
    },
    ref
  ) => {
    const disabled = defaultDisabled || isLoading;
    const colorProps = disabled ? disabledColor : colors[color];
    return (
      <ChakraButton
        ref={ref}
        disabled={disabled}
        isLoading={isLoading}
        fontWeight="normal"
        {...colorProps}
        {...sizes[size]}
        {...props}
      >
        {props.children}
      </ChakraButton>
    );
  }
);

Button.displayName = 'Button';
