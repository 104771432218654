import Head from 'next/head';
import { FC } from 'react';

export type TitleProps = {
  title: string;
  override?: boolean;
};

export const Title: FC<TitleProps> = ({ title, override = false }) => {
  return (
    <Head>
      <title>
        {`
          ${title}
          ${override ? '' : '｜トレーニングシェア'}
        `}
      </title>
    </Head>
  );
};
