import Image, { ImageProps } from 'next/image';
import { FC } from 'react';

export type CoverImageProps = {} & ImageProps;

export const CoverImage: FC<CoverImageProps> = ({ alt, src, ...props }) => {
  return (
    <Image
      alt={alt}
      src={src}
      priority={false}
      fill
      sizes='(max-width: 800px) 100vw'
      {...props}
      style={{
        objectFit: 'cover',
        objectPosition: 'center center',
      }}
    />
  );
};

CoverImage.displayName = 'CoverImage';
