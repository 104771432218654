import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { HiOutlineMail } from 'react-icons/hi';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BackgroundImage } from '@/components/elements/BackgroundImage/BackgroundImage';
import { ButtonLink } from '@/components/elements/ButtonLink/ButtonLink';
import { Link } from '@/components/elements/Link/Link';
import { Container } from '@/components/layouts/Container/Container';
import { Wrapper } from '@/components/layouts/Wrapper/Wrapper';
import { Title } from '@/components/metas/Title/Title';

const IndexPage = () => {
  return (
    <Wrapper>
      <Title title='トレーニングシェア' override={true} />
      <Container>
        <Box position='fixed' top={0} left={0} right={0} bottom={0}>
          <BackgroundImage
            bg='lightOverlay'
            src='/images/splash.jpg'
            alt='トレーニングシェア'
          />

          <VStack h='100%' position='relative'>
            <VStack flex={1} justifyContent='center' pt={6}>
              <Carousel
                swipeable
                emulateTouch
                showStatus={false}
                showArrows={false}
                showThumbs={false}
              >
                <Box pb={16}>
                  <Box w='215px' margin='0 auto'>
                    <Image
                      src='/images/logoWithText.png'
                      width={100}
                      height={100}
                      alt='トレーニングシェア'
                    />
                  </Box>
                  <Text fontSize='sm' mt={12} mb={2}>
                    Our Mission
                  </Text>
                  <Heading fontSize='xl'>
                    情報格差を無くし
                    <br />
                    頑張る人の架け橋になる
                  </Heading>
                </Box>

                <Box pb={16}>
                  <Box w='215px' margin='0 auto'>
                    <Image
                      src='/images/logoWithText.png'
                      width={100}
                      height={100}
                      alt='トレーニングシェア'
                    />
                  </Box>
                  <Text fontWeight='bold' mt={8}>
                    この度はトレーニングシェアにアクセスしていただき
                    <br />
                    誠にありがとうございます！
                    <br />
                    トレーニングシェアは世界中で活躍している指導者が
                    <br />
                    最新の情報を共有(シェア)するサービスです。
                    <br />
                    トレーニングからマインドセットまで
                    <br />
                    新しい刺激や考え方を元に
                    <br />
                    ご自身の競技レベル、指導レベルを
                    <br />
                    アップデートして行きましょう！
                  </Text>
                </Box>

                <Box pb={16}>
                  <Box w='215px' margin='0 auto'>
                    <Image
                      src='/images/logoWithText.png'
                      width={100}
                      height={100}
                      alt='トレーニングシェア'
                    />
                  </Box>
                  <Text fontWeight='bold' mt={8}>
                    さぁ、
                    <br />
                    新しい未来への扉を開きましょう！
                  </Text>
                </Box>

                <Box pb={16}>
                  <Box w='215px' margin='0 auto'>
                    <Image
                      src='/images/logoWithText.png'
                      width={100}
                      height={100}
                      alt='トレーニングシェア'
                    />
                  </Box>
                  <Heading fontSize='xl' mt={16}>
                    知る事で、強くなろう
                  </Heading>
                </Box>
              </Carousel>

              <Text>
                <Link href='https://lp.training-share.com/' target='_blank'>
                  詳しくはこちら
                </Link>
              </Text>
            </VStack>

            <VStack flexBasis='180px' justifyContent='center'>
              <ButtonLink leftIcon={<HiOutlineMail size={26} />} href='/signup'>
                メールアドレスで登録
              </ButtonLink>
              <ButtonLink href='/signin'>ログイン</ButtonLink>
              <Text fontSize='xs'>
                <Link href='/terms'>利用規約</Link>と
                <Link href='/privacy'>個人情報保護方針</Link>
                にご同意、
                <Link
                  target={'_blank'}
                  href='https://lp.training-share.com/tokushoho/'
                  rel='noopener noreferrer'
                >
                  特定商取引法に基づく表記
                </Link>
                をご確認の上、ご利用ください。
              </Text>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default IndexPage;
